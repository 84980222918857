//Utilities
import React from 'react'
import { Link } from 'react-router-dom'

//Images
import launchImage from '../../assets/images/eda-provider-meeting.jpg'

function CTA() {
  return (
    <>
      {/*Partner CTA */}
      <section id="about" className="bg-white py-16">
        {/*CTA Container */}
        <div className="container flex flex-col-reverse items-center px-6 lg:py-16 mx-auto lg:flex-row lg:space-x-16">
          {/* CTA Content */}
          <div className="flex flex-col items-start lg:w-1/2 p-4">
            <div className="flex flex-col space-y-5">
              <h2 className="text-4xl mb-2 font-bold">Why Platypus?</h2>
              
              <div className='space-y-5'>
                <div>
                  <h3 className='font-bold mb-2'>Platypus focuses on the most up-to-date and comprehensive healthcare knowledge and best practices.</h3>
                  <ul className="font-extralight list-disc space-y-3 mx-4" >
                        <li>Regularly track regulatory changes and identify compliance gaps</li>

                        <li>Accelerate AI adoption through trust and transparency</li>

                        <li>Quickly translate technical guidelines into actionable recommendations</li>
                      
                      </ul>
                </div>

              </div>

              <p className="font-extralight text-lg text-gray-900">Transform your AI organization.</p>
            </div>

            {/* Button */}
            <div className="py-12 mx-auto md:m-0">
              <Link to="/connect-with-us" className="p-4 text-center text-sm font-semibold text-black bg-platyGold border-gray-300 rounded shadow-md md:text-base hover:bg-platyBlue hover:text-platyGray">Connect with us</Link>
            </div>
          </div>

          {/* CTA Image */}
          <div className="lg:w-1/2 p-4 mb-8">
            <img 
              src={launchImage}
              title="Team collaboration with Platypus"
              alt="Team standing together"
              height="500"
              width="750"
              loading="lazy"
            />
          </div>
        </div>
      </section>
    </>
  )
}

export default CTA