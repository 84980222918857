//Utilities
import React from 'react'

//Images and Icons
import Leigh from '../../assets/images/leigh.jpg'
import Eric from '../../assets/images/eric.jpg'
import { FaLinkedinIn } from "react-icons/fa";

function ExecutiveQuote() {
  return (
    <>
      {/* Executive Quote */}
      <section id="features" className="bg-white-txt-bg bg-cover py-16">
        <div className="text-center items-center">
          <h2 className='text-2xl'>Built by Passion and Lived Experience.</h2>

          <div>
            {/* Image */}
            <div className="container flex flex-col items-center lg:flex-row m-10 p-10 mx-auto text-center justify-center">

            <div className="lg:w-1/2 p-4 roudned-full">
              <img 
                className='mx-auto rounded-full' 
                src={Leigh}
                title="Leigh McCormack"
                alt="Leigh McCormack founder of Platypus Health"
                height="199"
                width="199"
                loading="lazy"
              />

              <div>
                <h3 className='mt-2'>Leigh McCormack</h3>
                <a target="_blank" rel="noreferrer" alt="Leigh LinkedIn" href="https://www.linkedin.com/in/leigh-mccormack/"><FaLinkedinIn className='mx-auto'/></a>
              </div>

            </div>

            {/* Image */}
            <div className="lg:w-1/2 p-4">
              <img 
                className='mx-auto rounded-full' 
                src={Eric}
                title="Eric Brengle"
                alt="Eric Brengle headshot"
                height="199"
                width="199"
                loading="lazy"
              />

              <div>
                <h3 className='mt-2'>Eric Brengle</h3>
                <a target="_blank" rel="noreferrer" alt="Eric LinkedIn" href="https://www.linkedin.com/in/eric-brengle/"><FaLinkedinIn className='mx-auto'/></a>
              </div>
              
            </div>
            </div>
            
          </div>
        </div>
      </section>

      <section className='bg-gray py-16'>


        <div className="container max-w-5xl flex flex-col-reverse items-center lg:flex-row m-10 p-10 mx-auto bg-platyDarkGray justify-center rounded-lg">

          {/* Quote */}
          <div className="p-10">
            <div className="text-2xl font-light">“Healthcare organizations, policymakers, and innovators have the opportunity to address AI risks proactively. Together, we can leverage technology that enhances our experience with AI, improving outcomes and creating a more effective healthcare system.”</div>
            
            <div className="mt-4">
              <div className="text-sm font-semibold">Leigh McCormack</div>
              <div className="text-xs">CEO, Founder Platypus</div>
            </div>

          </div>
        </div>
      </section>
    </>
  )
}

export default ExecutiveQuote