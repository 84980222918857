// Utility
import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async';

//Components
import HeroSection from './homepage-sections/Hero';
import FeaturesSection from './homepage-sections/Features';
import CTA from './homepage-sections/CTA';
import ExecutiveQuote from './homepage-sections/ExecutiveQuote';


//Images
import home from '../assets/images/homepage.png'


function Homepage() {
  return (
    <>
      <HelmetProvider>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Platypus Health | Enabling effective Healthcare AI</title>
            <meta name="description" content="Welcome to Platypus, a data science platform providing the ability to explore, validate, and implement equitable data and analytical tools to reduce waste and inequities." />
            <link rel="canonical" href={`https://www.platypus.health/`} />

            <meta property="og:title" content="Platypus Health | AI and Analytics" />
            <meta property="og:description" content="Enabling effective Healthcare AI" />
            <meta property="og:image" content={`https://www.platypus.health${home}`} />
            <meta property="og:url" content="https://www.platypus.health/" />
            <meta property="og:type" content="website" />
        </Helmet>
      </HelmetProvider>

      <HeroSection />

      <FeaturesSection />

      <CTA />

      <ExecutiveQuote />

    </>

  )

}

export default Homepage