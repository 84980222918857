// Utilities
import { Helmet, HelmetProvider } from 'react-helmet-async';

// Images

//Componenets
import Form from './widgets/ContactForm'
import DownloadWhitePaperCTA from './widgets/DownloadWhitePaperCTA'


function ConnectPage() {

  return (
    <>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Platypus Health | Connect with us</title>
          <meta name="description" content="Connect with Platypus Health." />
          <link rel="canonical" href="https://www.platypus.health/connect-with-us" />
        </Helmet>
      </HelmetProvider>


      {/*Connect */}
      <section id="about" className="bg-platyGray py-16">
        {/*Connect Container */}
        <div className="container flex flex-col px-6 mx-auto max-w-3xl md:space-x-16">
            {/* Content */}
            <div className="p-4 space-y-5">
              {/* Text Section */}
              <h1 className=" text-3xl font-semibold mx-auto text-platyGrayText md:text-4xl">Connect with us</h1>
              <div>
                <Form />
              </div>

            </div>

            {/* Form */}
            {/* <div className="flex flex-col lg:w-1/2 p-4">
              <div className="flex flex-col space-y-5">

                <Form />

              </div>
            </div> */}
        </div>
      </section>
  </>
  )
}

export default ConnectPage